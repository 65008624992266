import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Container} from "aurelia-dependency-injection"
import {CurrencyValueConverter} from "../../currency/currency-value-converter"
import {CurrencyInput, DateSelect, EntityPickerWithSelect, FormItem, PercentageInput} from "../../form/rsuite-form"
import {Col, Divider, Dropdown, FlexboxGrid, Form, IconButton} from "rsuite"
import MoveDownIcon from '@rsuite/icons/MoveDown'
import SioIcon from "../../icon/rsuite-icon-font/SioIcon"
import {selectOrganization} from "../store/entries-slice"
import {AccountSelect} from "./form-elements"
import {
    addPart,
    changePartAccount,
    changePartCr,
    changePartDr,
    changePartPercentage,
    removePart,
    selectBooking,
    selectBookingPart,
    selectCanRemovePart,
    selectErrors,
    selectPartCount,
    selectSums,
    selectTaxChoices,
    setBooking,
    splitTax
} from "../store/editor-slice"
import Help from "../../tourism/journey-calculation/components/help";

const currencyConverter = Container.instance.get(CurrencyValueConverter);

export default function EntryEditorComplex() {
    const dispatch = useDispatch()
    const booking = useSelector(selectBooking)
    const partCount = useSelector(selectPartCount)
    const [drSum, crSum] = useSelector(selectSums)

    return (
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
                <BookingBase/>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={24}>
                <Divider/>
                <PartRow>
                    <PartCol>Konto</PartCol>
                    <PartCol className="text-right">Soll</PartCol>
                    <PartCol className="text-right">Haben</PartCol>
                    <PartCol className="text-right">
                        {"autom. aufteilen "}
                        <Help>
                            <p>Hiermit lassen sich Buchungen mit mehreren Steuersätzen oder anderen
                                anteiligen Kosten (z. B. Disago) automatisch berechnen.</p>
                            <p>Tragen Sie dazu in der obersten Zeile den Bruttobetrag und der
                                zweiten Zeile den ersten Prozentwert ein. Das System berechnet dann
                                eine dritte Zeile mit den Restbeträgen. In dieser können Sie dann
                                den nächsten Prozentwert eintragen und immer so weiter.</p>
                            <p>Die Prozentwerte werden automatisch entfernt, sobald ein Buchungsbetrag
                                geändert wird.</p>
                        </Help>
                    </PartCol>
                    <PartCol>
                        {"Steuer "}
                        <Help>
                            <p>Hiermit kann die ausgewählte Zeile automatisch in den Nettobetrag und eine
                                zusätzliche Steuerbuchungszeile aufgeteilt werden.</p>
                        </Help>
                    </PartCol>
                </PartRow>

                {[...Array(partCount).keys()].map(index => (
                    <BookingPart key={index} index={index}/>
                ))}

                <PartRow>
                    <PartCol>
                        <IconButton appearance="ghost" size="xs" icon={<SioIcon icon="fa fa-plus"/>}
                                    onClick={() => dispatch(addPart())}/>
                    </PartCol>
                    <PartCol className="text-right">
                        {currencyConverter.toView({amount: drSum, currency: "EUR"})}
                    </PartCol>
                    <PartCol className="text-right">
                        {currencyConverter.toView({amount: crSum, currency: "EUR"})}
                    </PartCol>
                </PartRow>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

function BookingBase() {
    const dispatch = useDispatch()
    const booking = useSelector(selectBooking)
    const errors = useSelector(selectErrors)
    const organization = useSelector(selectOrganization)
    const {bookDate, receiptDate, receiptNumber, period, subject, costObject, reference} = booking

    const onChange = ({bookDate, receiptDate, receiptNumber, subject, costObject, reference}) => {
        dispatch(setBooking({
            ...booking,
            bookDate: bookDate.toISOString(),
            receiptDate: receiptDate?.toISOString(),
            receiptNumber,
            subject,
            costObject,
            reference,
        }));
    };

    const formValue = {
        receiptNumber, period, subject, costObject, reference,
        bookDate:  bookDate == null ? null : new Date(bookDate),
        receiptDate:  receiptDate == null ? null : new Date(receiptDate)
    };

    const formError = !errors ? undefined : {parts: parts.map(() => ({}))};

    if (errors) {
        for (const field of ["bookDate", "receiptDate", "receiptNumber", "subject", "costObject", "reference"]) {
            if (errors[field]?.errors) {
                formError[field] = errors[field].errors.join(" ");
            }
        }
    }

    return (
        <Form fluid formValue={formValue} formError={formError} onChange={onChange}>
            <FormItem field="bookDate" label="Buchdatum" accepter={DateSelect} cleanable={false}
                      col={{xs: 12, sm: 8, md: 8, lg: 4}}/>
            <FormItem field="receiptDate" label="Belegdatum" accepter={DateSelect} cleanable={false}
                      col={{xs: 12, sm: 8, md: 8, lg: 4}}/>
            <FormItem field="receiptNumber" label="Belegnr." col={{xs: 24, sm: 8, md: 8, lg: 4}}/>
            {/*<FormItem field="period" label="Geschäftsjahr" accepter={PeriodSelect} organization={organization}/>*/}
            {/*<FormItem field="tax" label="Steuer" accepter={InputPicker} data={TAX} cleanable={false} block col={{xs: 6, lg: 4}}/>*/}
            <FormItem field="subject" label="Betreff" col={{xs: 24, sm: 24, md: 24, lg: 12}}/>
            <div className="clearfix"/>
            <FormItem field="costObject" label="Kostenträger" accepter={EntityPickerWithSelect}
                      modelId="accounting/cost-object" col={{xs: 24, sm: 12, md: 12, lg: 4}}/>
            <FormItem field="reference" label="Referenz" accepter={EntityPickerWithSelect}
                      modelId="accounting/booking-reference" col={{xs: 24, sm: 12, md: 12, lg: 4}}/>
        </Form>
    )
}

function BookingPart({index}) {
    const dispatch = useDispatch()
    const {account, cr, dr, percentage} = useSelector(selectBookingPart(index))
    const organization = useSelector(selectOrganization)
    const canRemovePart = useSelector(selectCanRemovePart)
    const formError = useSelector(selectErrors)

    const currency = account?.currency ?? "EUR";
    const formValue = {
        account: account ? {id: account.id, currency, modelId: "accounting/ledger-account"} : null,
        dr: {amount: dr, currency},
        cr: {amount: cr, currency},
        percentage
    };

    return (
        <Form fluid formValue={formValue} formError={formError?.parts[index]} key={index}
            // onChange={/*onChangePart(key)*/}
        >
            <PartRow>
                <SplitFormItem
                    field="account"
                    label="Konto"
                    accepter={AccountSelect}
                    organization={organization}
                    onChange={account => dispatch(changePartAccount(index, account))}
                />

                {cr ? <PartCol/> : (
                    <SplitFormItem
                        field="dr"
                        label="Soll"
                        min={0}
                        accepter={CurrencyInput}
                        onChange={({amount}) => dispatch(changePartDr(index, amount))}
                    />
                )}

                {dr ? <PartCol/> : (
                    <SplitFormItem
                        field="cr"
                        label="Haben"
                        min={0}
                        accepter={CurrencyInput}
                        onChange={({amount}) => dispatch(changePartCr(index, amount))}
                    />
                )}

                {0 === index ? <><PartCol/><PartCol/></> : (
                    <>
                        <SplitFormItem
                            field="percentage"
                            min={0}
                            accepter={PercentageInput}
                            onChange={percentage => dispatch(changePartPercentage(index, percentage))}
                        />

                        <TaxAction index={index}/>
                    </>
                )}

                {canRemovePart && (
                    <PartCol>
                        <IconButton appearance="ghost" size="xs" icon={<SioIcon icon="fa fa-minus"/>}
                                    onClick={() => dispatch(removePart(index))}
                        />
                    </PartCol>
                )}
            </PartRow>
        </Form>
    );
}

const PartRow = ({children}) => (
    <FlexboxGrid align="middle" style={{marginBottom: ".5rem"}}>
        {children}
    </FlexboxGrid>
)

const PartCol = ({className, children}) => (
    <FlexboxGrid.Item as={Col} xs={8} md={6} lg={4} className={className}>
        {children}
    </FlexboxGrid.Item>
)

const SplitFormItem = ({label, field, accepter, ...props}) => (
    <PartCol>
        <Form.Group controlId={field}>
            <Form.Control name={field} accepter={accepter} placeholder={label} {...props}/>
        </Form.Group>
    </PartCol>
)

function TaxAction({index}) {
    const dispatch = useDispatch()
    const taxChoices = useSelector(selectTaxChoices)

    return (
        <PartCol>
            <Dropdown title={<MoveDownIcon/>} placement="topStart">
                {taxChoices.map(({label, value}) => !value?.length ? null : (
                    <Dropdown.Item key={value} onClick={() => dispatch(splitTax(index, value))}>{label}</Dropdown.Item>
                ))}
            </Dropdown>
        </PartCol>
    )
}
